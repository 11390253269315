<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Services
			</v-flex>
			<v-flex class="text-right my-auto"> Total Services Cost: {{ totalCost }} </v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="20" class="p-2 light-blue-bg"></th>
						<th class="p-2 light-blue-bg">Type</th>
						<th class="p-2 light-blue-bg">Status</th>
						<th class="p-2 light-blue-bg">Description</th>
						<th class="p-2 light-blue-bg">Cost</th>
						<th class="p-2 light-blue-bg">Serviced By</th>
						<th class="p-2 light-blue-bg">Start Date (actual)</th>
						<th class="p-2 light-blue-bg">End Date (actual)</th>
					</tr>
				</thead>
				<tbody v-if="services.length">
					<tr v-for="(row, index) in services" :key="index">
						<td v-on:click="routeToDetail(row)" width="20" class="p-2 cursor-pointer">
							<v-chip v-if="row.type == 1" color="blue darken-4" text-color="white">M</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">S</v-chip>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue :object="row" object-key="service_type" label="Type"></ShowValue>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<v-chip v-if="row.status == 1" color="blue darken-4" text-color="white">Started</v-chip>
							<v-chip v-else-if="row.status == 2" color="green" text-color="white">Completed</v-chip>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer d-grid" width="200">
							<ShowValue :object="row" object-key="description" truncate label="Description"></ShowValue>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue :object="row" object-key="cost_formatted" label="Cost"></ShowValue>
						</td>
						<td class="p-2">
							<template v-if="row.service_by == 1">
								Supplier (<ShowValue
									:object="row"
									object-key="supplier_display_name"
									label="Supplier"
								></ShowValue
								>)
							</template>
							<template v-else>
								Staff (<ShowValue :object="row" object-key="member_display_name" label="Staff"></ShowValue>)
							</template>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue
								:object="row"
								object-key="actual_start_date_formatted"
								label="Start Date (actual)"
							></ShowValue>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue
								:object="row"
								object-key="actual_end_date_formatted"
								label="End Date (actual)"
							></ShowValue>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no service at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="detailDialog" :dialog-width="dialogWidth" dense>
			<template v-slot:title>
				<v-layout>
					<v-flex md4 class="my-auto"> {{ service.service_type }} Service </v-flex>
					<v-flex md8 class="text-right">
						<v-btn
							v-if="service.status == 3"
							color="blue darken-4 text-white"
							class="mr-2"
							depressed
							v-on:click="startScheduledService"
							tile
							><v-icon small left>mdi-hammer-wrench</v-icon>Start</v-btn
						>
						<v-btn
							v-if="service.status == 1"
							v-on:click="serviceCompleteDialog = true"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-format-list-checks</v-icon>Complete</v-btn
						>
						<v-btn
							v-if="service.status == 1"
							v-on:click="serviceExtendDialog = true"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-share-all-outline</v-icon>Extend</v-btn
						>
						<v-btn
							v-on:click="detailDialog = false"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-close</v-icon>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-tabs
						v-model="serviceTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent"
						active-class="blue darken-4 text-white"
						hide-slider
					>
						<v-tab href="#detail">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							Details
						</v-tab>
						<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
						<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					</v-tabs>
					<v-tabs-items v-model="serviceTab">
						<v-tab-item value="detail">
							<div class="mx-4 mb-4">
								<div class="overflow-y" style="max-height: 600px; min-height: 400px">
									<v-layout class="p-4 border-bottom-light-grey min-height-57px">
										<v-flex class="font-level-3-bold my-auto">
											<span class="detail-svg-icon mr-2">
												<!--begin::Svg Icon-->
												<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
												<!--end::Svg Icon-->
											</span>
											Service Details
										</v-flex>
									</v-layout>
									<table width="100%" class="detail-table scattered-table">
										<tr>
											<th class="p-2" width="250px">Service Type</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="service_type" label="service type"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service Start Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_start_date_formatted"
													label="service start date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Cost</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="cost_formatted" label="Cost"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service End Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_end_date_formatted"
													label="Service End Date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Service By{{ service.service_by_formatted }}</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="service_by_formatted"
													label="Service By"
												></ShowValue>
												(<ShowValue
													:object="service"
													v-if="service.service_by == 1"
													object-key="supplier_display_name"
													label="vendor"
												></ShowValue>
												<ShowValue
													:object="service"
													v-else
													object-key="member_display_name"
													label="member"
												></ShowValue
												>)
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Created By</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="created_by" label="Created By"></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Location By</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="location_by_formatted"
													label="Loaction By"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Description</th>
											<td class="font-level-1 p-2" colspan="3">
												<ShowValue :object="service" object-key="description" label="Description"></ShowValue>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								class="mx-4"
								type-text="Service Files"
								type="service"
								:type-uuid="service.uuid"
								:type-id="service.id"
							></Files>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								class="mx-4"
								type-text="Service"
								type="service"
								:type-uuid="service.uuid"
								:type-id="service.id"
							></Comments>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</Dialog>
		<Dialog :dialog="inventoryDialog" :dialog-width="dialogWidth - 200" dense>
			<template v-slot:title> Link Perishables Items </template>
			<template v-slot:body>
				<LinkAssetInventoryItem v-model="inventories" class="mx-4 mb-4"></LinkAssetInventoryItem>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="closeLinkInventory()" color="blue darken-4 text-white" depressed tile
					>Close</v-btn
				>
				<v-btn v-on:click="linkInventory()" color="blue darken-4 text-white" depressed tile
					>Link Perishables</v-btn
				>
			</template>
		</Dialog>
		<ServiceDamageCompleteTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceCompleteDialog = false;
				detailDialog = false;
				getServices();
			"
			v-on:close="serviceCompleteDialog = false"
			:service-dialog="serviceCompleteDialog"
		></ServiceDamageCompleteTemplate>
		<ServiceExtendTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceExtendDialog = false;
				detailDialog = false;
				getServices();
			"
			v-on:close="serviceExtendDialog = false"
			:service-dialog="serviceExtendDialog"
		></ServiceExtendTemplate>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import { AssetServiceLinkInventory, GetAssetServiceLinkInventory } from "@/core/lib/asset.lib";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ServiceDamageCompleteTemplate from "@/view/components/ServiceDamageCompleteTemplate";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
import LinkAssetInventoryItem from "@/view/components/LinkAssetInventoryItem";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import ApiService from "@/core/services/api.service";

export default {
	data() {
		return {
			services: [],
			linkedInventories: [],
			inventoryList: [],
			inventories: [],
			pageLoading: false,
			detailDialog: false,
			inventoryDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			searchLoading: false,
			formLoading: false,
			menuSearch: false,
			showingString: null,
			search: null,
			serviceTab: "detail",
			currentPage: 1,
			totalCost: 0,
			totalPage: 1,
			service: {},
			timeout: null,
			timeoutLimit: 500,
		};
	},
	props: {
		assetUuid: {
			type: String,
			data: null,
		},
	},
	methods: {
		closeLinkInventory() {
			this.inventoryList = [];
			this.inventories = [];
			this.inventoryDialog = false;
			this.searchLoading = false;
			this.formLoading = false;
			this.menuSearch = false;
			this.search = null;
		},
		startScheduledService() {
			this.pageLoading = true;
			ApiService.patch(`asset/${this.assetUuid}/service/${this.service.uuid}/start`)
				.then(() => {
					this.detailDialog = false;
					this.getServices();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getLinkedInventories() {
			if (!this.service.uuid) {
				return false;
			}

			GetAssetServiceLinkInventory(this.assetUuid, this.service.uuid)
				.then((data) => {
					this.linkedInventories = data;
				})
				.catch((error) => {
					this.logError(error);
				});
		},
		linkInventory() {
			if (!this.service.uuid) {
				return false;
			}

			if (!this.inventories.length) {
				this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Error ! Add atleast one inventory." }])
				);
				return false;
			}

			this.formLoading = true;
			AssetServiceLinkInventory(this.assetUuid, this.service.uuid, { inventory: this.inventories })
				.then((data) => {
					this.linkedInventories = data;
					this.closeLinkInventory();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Perishables has been successfully linked to service." },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.formLoading = false;
				});
		},
		routeToDetail(row) {
			this.service = row;
			this.detailDialog = true;
		},
		getServices() {
			ApiService.setHeader();
			ApiService.get(`asset/${this.assetUuid}/damage-services`).then(({ data }) => {
				this.services = data.tbody;
				this.$emit("servicedata", this.services);
			});
		},
	},
	components: {
		Dialog,
		ShowValue,
		Comments,
		Files,
		ServiceExtendTemplate,
		LinkAssetInventoryItem,
		ServiceDamageCompleteTemplate,
	},
	mounted() {
		this.getServices();

		EventBus.$on("reload:asset-service", () => {
			this.getServices();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-service");
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 65);
		},
	},
};
</script>
